<template>
  <div>
    <vs-row>
      <vs-col vs-w="6" id="content" class="vs-con-loading__container">
        <vx-card :title="$t('common.registerData')">
          <div class="vx-row">
            <div class="vx-col w-full">
              <vs-input
                class="w-full mt-4"
                label="Id"
                v-model="data_local.id"
                name="id"
                v-if="data_local.id"
                disabled
              />
              <vs-input
                class="w-full mt-4"
                :label="$t('common.name')"
                v-model="data_local.group_name"
                name="group_name"
                v-validate="'required'"
                :data-vv-as="$t('common.name')"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('group_name')"
                >{{ errors.first("group_name") }}</span
              >

              <div class="mt-4">
                <label class="vs-input--label">{{
                  $t("machine.company")
                }}</label>
                <v-select
                  v-model="data_local.company_id"
                  :clearable="false"
                  :options="companyOptions"
                  name="company"
                  :searchable="true"
                  :reduce="x => x.id"
                  label="name"
                  :data-vv-as="$t('machine.company')"
                  :disabled="loadingCompanies"
                  v-validate="'required'"
                >
                  <span slot="no-options">{{ $t("navbar.noResults") }}</span>
                </v-select>
                <span
                class="text-danger text-sm"
                v-show="errors.has('company')"
                >{{ errors.first("company") }}</span
              >
              </div>

              <div class="mt-4">
                <label class="vs-input--label">{{
                  $t("machine.machines")
                }}</label>
                <v-select
                  v-model="data_local.machines"
                  name="machines"
                  multiple
                  :closeOnSelect="false"
                  :options="machineOptions"
                  :reduce="x => x.id"
                  label="serial_number"
                  :disabled="loadingMachines || !machineOptions.length"
                  v-validate="'required'"
                  :data-vv-as="$t('machine.machines')"
                >
                  <span slot="no-options">{{ $t("navbar.noResults") }}</span>
                </v-select>
                <span
                class="text-danger text-sm"
                v-show="errors.has('machines')"
                >{{ errors.first("machines") }}</span
              >
              </div>

              <div class="mt-4">
                <label class="vs-input--label">{{ $t("user.users") }}</label>
                <v-select
                  v-model="data_local.users"
                  name="users"
                  multiple
                  :closeOnSelect="false"
                  :options="userOptions"
                  :reduce="x => x.id"
                  label="name"
                  :disabled="loadingUsers || !userOptions.length"
                   v-validate="'required'"
                  :data-vv-as="$t('user.users')"
                >
                  <span slot="no-options">{{ $t("navbar.noResults") }}</span>
                </v-select>
                <span
                class="text-danger text-sm"
                v-show="errors.has('users')"
                >{{ errors.first("users") }}</span
              >
              </div>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-full">
              <div class="mt-8 flex flex-wrap items-center justify-end">
                <vs-button
                  id="button-with-loading"
                  class="ml-auto mt-2 vs-con-loading__container"
                  @click="save"
                  :disabled="
                    !validateForm || $store.state.AppActiveUser.type === 0
                  "
                  >{{ $t("actions.save") }}</vs-button
                >
                <vs-button
                  class="ml-4 mt-2"
                  type="flat"
                  color="dark"
                  @click.native="$router.back()"
                  >{{ $t("actions.cancel") }}</vs-button
                >
              </div>
            </div>
          </div>
        </vx-card>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import vSelect from "vue-select";
import i18n from "@/i18n/i18n";

export default {
  components: {
    vSelect
  },
  data: () => ({
    data_local: {},
    loadingMachines: true,
    machineOptions: [],
    loadingUsers: false,
    userOptions: [],
    loadingCompanies: false,
    companyOptions: [],
    loading: true
  }),
  methods: {
    async loadData() {
      var self = this;
      
      this.$vs.loading({
        container: "#content",
        scale: 0.5
      });

      await this.$store
        .dispatch("httpRequest", {
          method: "get",
          url: `machine-group/${this.$route.params.id}`
        })
        .then(data => {
          self.data_local = data;
          self.loading = false;
          this.$vs.loading.close("#content > .con-vs-loading");
        })
        .catch(error => {
          this.$vs.loading.close("#content > .con-vs-loading");
          this.$vs.notify({
            title: i18n.t("dialog.error"),
            text: error.message,
            color: "danger"
          });
        });
    },
    save() {
      this.$validator.validateAll().then(result => {
        if (result) {
          var self = this;

          this.$vs.loading({
            container: "#button-with-loading",
            scale: 0.5
          });

          var isNew = this.$route.params.new;

          var url = isNew ? "machine-group/create" : "machine-group/edit";
          var message = isNew
            ? i18n.t("group.messages.success.create")
            : i18n.t("group.messages.success.edit");

          this.$store
            .dispatch("httpRequest", {
              method: "post",
              url: url,
              data: this.data_local
            })
            .then(() => {
              self.$vs.loading.close("#button-with-loading > .con-vs-loading");
              this.$router.push("/group");

              this.$vs.notify({
                title: i18n.t("dialog.success"),
                text: message,
                color: "success"
              });
            })
            .catch(error => {
              self.$vs.loading.close("#button-with-loading > .con-vs-loading");
              this.$vs.notify({
                title: i18n.t("dialog.error"),
                text: error.message,
                color: "danger"
              });
            });
        }
      });
    },
    async loadMachines() {
      var self = this;
      self.loadingMachines = true;
      await this.$store
        .dispatch("httpRequest", {
          method: "get",
          url: `machine-group/company/${this.data_local.company_id}/machines`
        })
        .then(data => {
          self.machineOptions = data;
          self.loadingMachines = false;
        })
        .catch(error => {
          this.$vs.notify({
            title: i18n.t("dialog.error"),
            text: error.message,
            color: "danger"
          });
        });
    },
    async loadUsers() {
      var self = this;
      self.loadingUsers = true;

      await this.$store
        .dispatch("httpRequest", {
          method: "get",
          url: `machine-group/company/${this.data_local.company_id}/users`
        })
        .then(data => {
          self.userOptions = data;
          self.loadingUsers = false;
        })
        .catch(error => {
          this.$vs.notify({
            title: i18n.t("dialog.error"),
            text: error.message,
            color: "danger"
          });
        });
    },
    async loadCompanies() {
      let self = this;
      self.loadingCompanies = true;
      await this.$store
        .dispatch("httpRequest", { method: "get", url: "company/all" })
        .then(data => {
          self.companyOptions = data.filter(x => x.active == true);
          self.loadingCompanies = false;
        })
        .catch(error => {
          this.$vs.notify({
            title: i18n.t("dialog.error"),
            text: error.message,
            color: "danger"
          });
        });
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    }
  },
  watch: {
    async "data_local.company_id"() {
      await this.loadUsers();
      await this.loadMachines();
    }
  },
  async mounted() {
    this.$vs.loading({
      container: "#content",
      scale: 0.5
    });
    await this.loadCompanies();

    this.$vs.loading.close("#content > .con-vs-loading");

    if (this.$route.params.id) {
      this.loadData();
    }
  },
  created() {
    this.data_local = this.$route.params.group || {};
  }
};
</script>

<style lang="scss" scoped>
.vs-con-loading__container {
  overflow: unset;
}
</style>
